.matrix-teamMembers {
    margin: 0px auto;
    .team-members-header {
        text-align: center;
        margin-bottom: 70px;
        .text,
        h3 {
            text-align: center;
            max-width: 600px;
            margin: 0 auto;
        }
        .text {
            margin-bottom: 90px;
            position: relative;
            p {
                color: rgb(73, 75, 80);
                font-size: 20px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 33px;
                text-align: center;
                &.lead {
                    @media (max-width: 768px) {
                        font-size: 18px;
                        line-height: 29px;
                    }
                }
            }
            .floating-arrow {
                position: absolute;
                left: -222px;
                top: 75px;
                svg {
                    width: 150px;
                }
                @media (max-width: 1230px) {
                    display: none;
                }
            }
        }
        @media (max-width: 768px) {
            margin: 20px;
            margin-top: 50px;
            .text {
                margin-bottom: 50px;
            }
            h3 {
                font-size: 24px;
            }
        }
    }

    .team-members-grid {
        width: 1230px;
        margin: 40px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .team-member {
            flex: 0 0 calc(25% - 22.5px);
            margin-bottom: 65px;
            @media (max-width: 768px) {
                margin-bottom: 40px;
            }
            @media (min-width: 896px) and (max-width: 1230px) {
                margin-left: 5px;
                margin-right: 5px;
            }
            img {
                width: 100%;
                margin-bottom: 25px;
                @media (max-width: 768px) {
                    margin-bottom: 19px;
                }
            }
            h4 {
                margin-bottom: 10px;
                .person-flag {
                    margin-left: 6px;
                    font-size: 20px;
                }
                @media (max-width: 768px) {
                    font-size: 20px;
                    .person-flag {
                        margin-left: 11px;
                    }
                }
            }
            .member-title {
                margin-bottom: 10px;
                color: #AAB0BD;
                font-size: 14px;
                font-weight: 600;
            }
            .member-fact {
                color: rgb(73, 75, 80);
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 28px;
            }

        }
        @media (max-width: 768px) {
            margin-top: 42px;
            margin-bottom: 19px;
            .team-member {
                flex: 0 0 calc(100% - 10px);
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        @media (min-width: 768px) and (max-width: 896px) {
            .team-member {
                flex: 0 0 calc(50% - 10px);
            }
        }
        @media (min-width: 896px) and (max-width: 1230px) {
            margin: 0px 10px;
            justify-content: center;
            .team-member {
                flex: 0 0 calc(33% - 10px);
            }
        }
        @media (max-width: 1230px) {
            width: calc(100% - 80px);
            margin: 0px 40px;
        }
        @media (max-width: 768px) {
            width: calc(100% - 40px);
            margin: 0px 20px;
        }
    }


}