






















































































































































































































































































.tippy-tooltip {
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.06)!important;
    border: 1px solid rgba(246,248,251,0.8);
}
.nav-outer {
    @media (max-width: 1023px) {
        height: 80px;
    }
}
.d-child-nav-list {
    color: #494B50;
    @media (max-width: 1023px) {
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        border-bottom: 1px solid transparent; // hack for ios issue
        max-height: 0;
        transition: transform .3s, opacity .3s, visibility .3s, max-height .2s .1s, padding .2s .2s;
        &:not(.mobile-active) {
            padding-top: 0px;
            padding-bottom: 0px;
        }
        &.mobile-active {
            transform: translateY(0px);
            opacity: 1;
            visibility: visible;
            max-height: 100vh;
            overflow-y: scroll;
            transition: transform .3s .1s, opacity .3s .1s, visibility .3s .1s, max-height .3s, padding .2s;
        }
    }
    @apply text-left;
    min-width: 356px;
    .child-item {
        position: relative;
        padding: 4px;
        &.flex-col {
            align-items: start;
        }
        &.external-link {
            svg {
                height: 10px;
                height: 10px;
                margin-top: -3px;
            }
            &:hover svg {
                transform: translateX(0px) translateY(-5px);
            }
        }
        img {
            width: 36px;
            height: 36px;
        }
        .nav-image {
            width: 100%;
            img {
                width: 126px;
                height: 36px;
            }
            &.the-admissions-club {
                border-top: 2px solid #F6F8FB;
                img {
                    margin-top: 2em;
                    width: 132px;
                    height: 44px;
                }
            }
            svg {
                opacity: 1;
            }
            &:hover {
                img.external-link {
                    transform: translateX(5px) translateY(-5px);
                }
            }
            img.external-link {
                height: 20px;
                width: 50px;
                margin-top: auto;
                margin-bottom: 8px;
                transition: transform 0.2s ease-in-out;
            }
        }
        svg {
            width: 18px;
            height: 20px;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 10px;
            transition: transform 0.4s, opacity 0.4s;
            transform: translateX(-10px);
            opacity: 0;
        }
        &:hover svg {
            @apply opacity-100;
            transform: translateX(0px);
        }
    }
    .is-product + .is-other {
        border-top: 2px solid #f6f8fb;
        padding-top: 30px;
        margin-top: 24px;
    }
}
.d-navigation {
    max-width: 1710px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 40px;
    z-index: 30;
    position: relative;
    @media (max-width: 1230px) {
        padding: 40px;
    }
    @media (max-width: 1023px) {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 1.25rem;
        height: 80px;
        transition: height 0s .3s;
        overflow: hidden;
        background-color: rgba(255,255,255,0);
        .nav-background {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            visibility: hidden;
            background: #fff;
            transition: opacity .3s, visibility .3s;
            z-index: -1;
        }
        &.mobile-active {
            position: fixed;
            overflow: hidden;
            height: 100vh;
            .nav-background {
                opacity: 1;
                visibility: visible;
            }
            .d-nav-list {
                position: relative;
                transition: height 0s;
                overflow-y: scroll;
                overflow-x: hidden;
                width: 100%;
                > li {
                    opacity: 1;
                    @for $i from 1 through 30 {
                        &:nth-child(#{$i}) {
                            transition: opacity 0.3s ($i * 0.1s);
                        }
                    }
                }
                &:after {
                    content: '';
                    display: block;
                    position: fixed;
                    bottom: 80px;
                    left: 0;
                    right: 0;
                    height: 40px;
                    opacity: 1;
                    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                    pointer-events: none;
                }
            }
            .burger {
                .burger-element {
                    .burger-layer {
                        background-color: #494b50 !important;
                    }
                }
            }
        }
        .d-nav-list {
            > li {
                transition: opacity .3s;
                opacity: 0;
                > a {
                    .nav-chevron {
                        @apply stroke-current;
                    }
                }
            }
        }
    }
    .desktop-nav {
        .d-nav-list {
            &:hover, &.dropdownActive {
                li {
                    a {
                        opacity: 0.5;
                        &:hover, &.dropdownActive {
                            opacity: 1;
                        }
                    }
                }
            }
            li {
                &:focus,
                div:focus,
                & > a:focus {
                    outline: none;
                }
                a {
                    transition: opacity .3s;
                    @media (min-width: 769px) {
                        margin: 0px 20px;
                    }
                    .nav-chevron {
                        @apply stroke-current;
                    }
                    svg {
                        transition: transform .3s;
                    }
                    &.dropdownActive {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
                &:first-of-type {
                    a {
                        margin-left: 0px;
                    }
                }
                &:last-of-type {
                    a {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
    .mobile-nav {
        max-width: 100%;
    }
    @media (max-width: 1023px) {
        .burger {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 50px;
            height: 44px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: transform .3s, background-color .3s;
            .burger-element {
                position: relative;
                width: 26px;
                height: 20px;
                transition: transform .3s;
                .burger-layer {
                    position: absolute;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 2px;
                    border-radius: 1px;
                    background-color: #494b50;
                    transition: transform .21s, margin .21s .22s, opacity .3s, top .21s .22s;
                    &:nth-child(1) {
                        top: 0;
                    }
                    &:nth-child(2) {
                        top: 50%;
                        margin-top: -1px;
                    }
                    &:nth-child(3) {
                        top: 100%;
                        margin-top: -2px;
                    }
                }
            }
        }
        .book-demo-mobile {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
        }
        &.mobile-active {
            .burger {
                .burger-element {
                    // transform: rotate(90deg);
                    transition: transform .5s;
                }
                .burger-layer {
                    transition: transform .21s .22s, margin .22s, opacity .3s, top .22s;
                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        margin-top: 0px;
                        top: 50%;
                    }
                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: rotate(45deg);
                        opacity: 0;
                    }
                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                }
            }
            .book-demo-mobile {
                opacity: 1;
                visibility: visible;
            }
        }
        .main-page {
            svg {
                transition: transform .3s;
            }
            &.mobile-active {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .digistorm-logo,
    .digistorm-logo-white {
        width: 144px;
        margin-top: 1px;
        @media (max-width: 1023px) {
            max-width: 133px;
        }
    }
    &.color-scheme-dark {
        @media (max-width: 1023px) {
            .digistorm-logo-white {
                display: none;
            }
        }
        @media (min-width: 1023px) {
            .digistorm-logo-white {
                @apply hidden;
            }
        }
        .color-scheme-dark-hide {
            @apply hidden;
        }
    }
    &.color-scheme-light {
        @media (max-width: 1023px) {
            .digistorm-logo, .digistorm-logo-white {
                transition: opacity .3s, visibility .3s;
            }
            .digistorm-logo {
                position: absolute;
                visibility: hidden;
                opacity: .5;
            }
            &.mobile-active {
                .digistorm-logo {
                    visibility: visible;
                    opacity: 1;
                }
                .digistorm-logo-white {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
        @media (min-width: 1023px) {
            .digistorm-logo {
                @apply hidden;
            }
            a {
                color: #ffffff;
            }
        }
        .color-scheme-light-hide {
            @apply hidden;
        }
        .burger {
            .burger-element {
                .burger-layer {
                    background: #fff;
                }
            }
        }
    }
}
