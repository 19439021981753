.home-logos {
     margin: 136px auto 133px;
     @media (max-width: 1023px) {
          margin: 47px 0;
     }
     .logos-subheading {
          display: flex;
          justify-content: center;
          width: 100%;
          text-align: center;
          color: rgb(43, 58, 90);
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 2.33px;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 50px;
     }
     .logos-logos {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
               position: absolute;
               left: 100%;
               top: 50%;
               margin-top: -30px;
               transform: scaleX(-1);
               path {
                    stroke: black;
               }
          }
          a {
               margin: 0 40px;
               img {
                    height: 50px;
                    width: auto;
                    &.mobile-image {
                         display: none;
                    }
               }
          }
          @media (max-width: 1023px) {
               flex-wrap: wrap;
               & > svg {
                    display: none;
               }
               a {
                    width: 50%;
                    margin: 30px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                         &.mobile-image {
                              display: block;
                         }
                         &.desktop-image {
                              display: none;
                         }
                    }
               }
          }
     }
}