.campaign-page {
    .video-banner-hero {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
        display: block;
        @screen lg {
            display: flex;
        }
        .media-header-wrapper.wrapper {
            padding: 40px 20px 70px;
            position: relative;
            @screen lg {
                align-items: start;
                padding: 0 40px 140px;
                max-width: 100%;
            }
            @screen xl {
                padding: 0 40px 100px;
                max-width: 1710px;
                margin-bottom: 140px;
            }
            &.btn-active {
                span.squiggle {
                    bottom: -100px;
                    @media screen and (min-width: 1400px) {
                        bottom: -80px;
                    }
                }
                .banner-header-content {
                    .squiggle {
                        bottom: -140px;
                    }
                    @screen lg {
                        margin-top: 10px;
                    }
                }
            }
            span.squiggle {
                left: 0;
                width: 100px;
                height: 100px;
                svg {
                    width: 100%;
                    height: 100%;
                    g {
                        stroke: var(--theme-primary);
                    }
                }
                @screen lg {
                    bottom: -10px;
                    left: 70px;
                }
                @screen xl {
                    width: 200px;
                    height: 200px;
                    left: 0;
                    bottom: -80px;
                    z-index: -1;
                }
                @media (min-width: 1280px) and (max-height: 850px) {
                    display: none !important;
                }
            }
            .video-preview {
                .preview-video {
                    position: relative;
                    padding: 0 0 20px;
                    @screen lg {
                        padding: 30px 0 0;
                    }
                    .video-placeholder {
                        overflow: hidden;
                        .looping-video {
                            width: 100%;
                            margin-left: -50%;
                        }
                        .placeholder-background {
                            border-radius: 8px;
                        }
                        &:hover {
                            .placeholder-background {
                                transform: scale(0.99);
                                border-radius: 8px;
                            }
                        }
                        .wistia_embed {
                            position: relative;
                        }
                        .wistia_embed img {
                            border-radius: 8px;
                        }
                    }
                }
            }
            .banner-header-content {
                padding: 0;
                h1 {
                    font-size: 32px;
                    line-height: 44px;
                    @screen md {
                        font-size: 40px;
                    }
                    @screen lg {
                        font-size: 42px;
                        line-height: 54px;
                        margin-bottom: 12px;
                        padding-top: 130px;
                    }
                    @screen xl {
                        font-size: 55px;
                        line-height: 70px;
                        margin-bottom: 30px;
                        padding-top: 140px;
                    }
                }

                @screen lg {
                    max-width: 495px;
                    margin-right: 50px;
                }
                @screen xl {
                    margin-right: calc(135 / 1920 * 100vw);
                }

                p {
                    font-size: 19px;
                    font-family: Barlow, sans-serif;
                    line-height: 31px;
                    font-weight: 400;
                    padding: 0;
                    @screen md {
                        font-size: 17px;
                    }
                    @screen lg {
                        font-size: 18px;
                        line-height: 28px;
                    }
                    @screen xl {
                        font-size: 22px;
                        line-height: 36px;
                    }
                }
                a.button {
                    position: relative;
                    z-index: 1;
                    color: white;
                    background-color: var(--theme-primary);
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0px;
                    line-height: 16px;
                    text-align: center;
                    border-radius: 4px;
                    height: 50px;
                    min-width: 180px;
                    padding: 17px 28px;
                    overflow: hidden;
                    display: block;
                    margin-top: 24px;
                    &:hover {
                        background-image: linear-gradient(rgb(0 0 0/2%) 0 0);
                    }
                    @screen xl {
                        margin-top: 40px;
                    }
                }
                .squiggle {
                    left: min(1vw, 65px);
                    bottom: -140px;
                    @media screen and (min-width: 1310px) {
                    }
                }
            }
            span.media-block-arrows {
                top: 0px;
                left: -75px;
                scale: 0.75;
                @screen xl {
                    left: -100px;
                    scale: 1;
                }

                svg path {
                    fill: var(--theme-primary);

                    &:last-child {
                        fill: var(--theme-secondary);
                    }
                }
            }
        }
    }
    &.theme-apps {
        .video-banner-hero {
            &:after {
                display: none;
            }
            .media-header-wrapper.wrapper {
                .image-header {
                    padding: 0;
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    &.theme-master {
        &:before {
            content: '';
            position: absolute;
            width: 200vw;
            height: 100vw;
            left: -28vw;
            bottom: 100%;
            transform: rotate(-20deg);
            background-color: #f6f8fb;
            z-index: -1;
        }
        .video-banner-hero {
            &:after {
                display: none;
            }
            .media-header-wrapper.wrapper {
                .image-header {
                    padding: 0;
                }
                span.squiggle {
                    svg g {
                        stroke: var(--theme-primary);
                    }
                }
            }
            .video-preview .preview-video .video-placeholder {
                .play-icon {
                    &:before {
                        border-color: #fff;
                    }
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                    &:after {
                        background: var(--theme-primary);
                    }
                }
                .play-text {
                    display: block;
                    color: #fff;
                    font-size: 0;
                    margin-left: 0;
                    transform: translateX(-50%);
                    &:after {
                        content: 'Play Case';
                        font-size: 14px;
                    }
                }
            }
        }
    }

    &.theme-funnel {
        .video-banner-hero {
            &:after {
                display: none;
            }

            .image-header {
                padding: 10px 0 0;
            }
        }
    }

    &.theme-websites {
        .video-banner-hero {
            &:after {
                display: none;
            }
            .video-banner-banner {
                align-items: initial;
                @screen lg {
                    // height: clamp(calc(100vh - 105px), 950px, 950px);
                    align-items: start;
                    height: 650px;
                }
                @screen xl {
                    height: clamp(727px, calc(100vh - 285px), 727px);
                    margin-bottom: 225px;
                }
            }
            .preview-video,
            .placeholder-background {
                position: static !important;
            }
            img {
                @screen xl {
                    position: absolute;
                    width: 979px !important;
                    height: 857px;
                    max-width: none;
                    left: -50px;
                    top: 0;
                    right: auto;
                    display: block;
                }
            }
        }
        .media-header-wrapper.wrapper span.media-block-arrows {
            top: 45px;
            left: -90px;
            display: none;
            transform: rotate(50deg);
            @screen lg {
                display: block;
            }
        }
    }
}
