// TODO fix this super lazy importing of vendor css for glider.js
.glider,.glider-contain{margin:0 auto;position:relative}.glider,.glider-track{transform:translateZ(0)}.glider-dot,.glider-next,.glider-prev{border:0;padding:0;user-select:none;outline:0}.glider-contain{width:100%}.glider{overflow-y:hidden;-webkit-overflow-scrolling:touch;-ms-overflow-style:none}.glider-track{width:100%;margin:0;padding:0;display:flex;z-index:1}.glider.draggable{user-select:none;cursor:-webkit-grab;cursor:grab}.glider.draggable .glider-slide img{user-select:none;pointer-events:none}.glider.drag{cursor:-webkit-grabbing;cursor:grabbing}.glider-slide{user-select:none;justify-content:center;align-content:center;width:100%;min-width:150px}.glider-slide img{max-width:100%}.glider::-webkit-scrollbar{opacity:0;height:0}.glider-next,.glider-prev{position:absolute;background:0 0;z-index:2;font-size:40px;text-decoration:none;left:-23px;top:30%;cursor:pointer;color:#666;opacity:1;line-height:1;transition:opacity .5s cubic-bezier(.17,.67,.83,.67),color .5s cubic-bezier(.17,.67,.83,.67)}.glider-next:focus,.glider-next:hover,.glider-prev:focus,.glider-prev:hover{color:#ccc}.glider-next{right:-23px;left:auto}.glider-next.disabled,.glider-prev.disabled{opacity:.25;color:#666;cursor:default}.glider-hide{opacity:0}.glider-dots{user-select:none;display:flex;flex-wrap:wrap;justify-content:center;margin:0 auto;padding:0}.glider-dot{display:block;cursor:pointer;color:#ccc;border-radius:999px;background:#ccc;width:12px;height:12px;margin:7px}.glider-dot:focus,.glider-dot:hover{background:#ddd}.glider-dot.active{background:#a89cc8}@media(max-width:36em){.glider::-webkit-scrollbar{opacity:1;-webkit-appearance:none;width:7px;height:3px}.glider::-webkit-scrollbar-thumb{opacity:1;border-radius:99px;background-color:rgba(156,156,156,.25);-webkit-box-shadow:0 0 1px rgba(255,255,255,.25);box-shadow:0 0 1px rgba(255,255,255,.25)}}

$updatesTouch: 1400px;

.updates-page-header {
    .d-navigation {
        max-width: 1440px !important;
    }
}
header.updates-header {
    background: white;
    margin-bottom: 6px;
    @screen md {
        margin-bottom: 36px;
    }
    height: 100px;
    display: flex;
    align-items: center;
    .header-content {
        width: 100%;
        max-width: 600px;
        padding: 20px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        @screen md {
            flex-direction: row;
        }
        .header-left {
            display: flex;
            align-items: center;
            span {
                margin-left: 14px;
                font-weight: 500;
                font-size: 14px;
                color: #858E99;
            }
        }
        .header-right {
            a {
                font-weight: 500;
                font-size: 14px;
                color: #494B50;
                opacity: 0.5;
                transition: opacity .3s;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

#updates-end {
    margin: 40px;
    color: #666;
    font-size: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    .emoji {
        font-size: 30px;
        margin: 0 10px;
    }

    &.hidden {
        display: none;
    }
}
.update-entry-title-link {
    display: inline-block;
    &:hover {
        div.title {
            text-decoration: underline;
        }
    }
}
.updates-entry-link {
    display: inline-block;
    color: rgb(73, 75, 80);
    font-size: 16px;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0px;
    margin-bottom: 55px;
    span {
        display: inline-block;
        transition: all 0.3s;
    }
    &:hover {
        span {
            transform: translateX(5px);
        }
    }
}
.updates-entry-back {
    color: rgba(73, 75, 80, 0.5);
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 14px;
    display: inline-block;
    transition: all 0.3s;
    &:hover {
        color: rgba(73, 75, 80, 1);
    }
}

.updates-page-wrapper {
    .updates-page-header {
        min-height: 600px;
        display: flex;
        flex-direction: column;
        @media (max-width: $updatesTouch) {
            min-height: auto;
        }
        &.updates-page-funnel {
            background: #2b2256;
        }
        nav.d-navigation {
            // so the hero content is perfectly centered
            // TODO ensure no overlap on mobile and different screen sizes etc
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            > div.flex {
                .desktop-nav {
                    > ul {
                        justify-content: flex-end;
                    }
                }
                .hidden:not(.desktop-nav) {
                    // display: none !important;
                }
            }
        }
        .updates-hero-content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            overflow: hidden;
            z-index: 5;
            .updates-hero-content-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 5;
                @media (max-width: $updatesTouch) {
                    padding: 140px 20px;
                }
                @media (max-width: 1023px) {
                    padding: 60px 20px 140px;
                }
                svg.updates-product-logo {
                    
                }
                h1 {
                    margin-top: 20px;
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 55px;
                    line-height: 70px;
                    text-align: center;
                    color: #FFFFFF;
                    @media (max-width: $updatesTouch) {
                        font-size: 32px;
                        line-height: 44px;
                    }
                }
                p {
                    margin-top: 28px;
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 36px;
                    text-align: center;
                    color: #FFFFFF;
                    @media (max-width: $updatesTouch) {
                        margin-top: 16px;
                        font-size: 19px;
                        line-height: 31px;
                    }
                }
            }
            svg.updates-product-logo-bg {
                position: absolute;
                right: -147px;
                bottom: -60px;
                pointer-events: none;
                z-index: 1;
                @media (max-width: $updatesTouch) {
                    max-width: 273px;
                    max-height: 247px;
                    right: -40px;
                    bottom: -40px;
                }
            }
        }
    }
    .updates-page-footer {
        background: #F6F8FB;
        .footer-inner {
            max-width: 1230px;
            margin: 0 auto;
            padding: 62px 20px 0px;
            .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 28px;
                @media (max-width: 1024px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 30px;
                }
                .links {
                    display: flex;
                    justify-content: flex-end;
                    gap: 40px;
                    @media (max-width: 1024px) {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 20px;
                    }
                    a {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 14px;
                        color: #494B50;
                        transition: opacity 0.15s;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
            .bottom {
                border-top: 1px solid rgba(#858E99, 0.1);
                height: 100px;
                display: flex;
                align-items: center;
                span {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    color: #858E99;
                }
            }
        }
    }
}

.updates-body {
    min-height: 750px;
    $content-width: 680px;
    $padding-width: 460px;
    $outer-width: calc(#{$content-width} + #{$padding-width * 2});
    display: flex;
    justify-content: center;
    position: relative;
    max-width: $outer-width;
    width: 100%;
    padding-left: $padding-width;
    padding-right: $padding-width;
    margin: 0 auto;
    @media (max-width: 1600px) {
        padding-left: #{$padding-width * 0.5};
        padding-right: #{$padding-width * 0.5};
    }
    @media (max-width: $updatesTouch) {
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .updates-filters {
        max-width: 280px;
        width: 100%;
        padding-top: 100px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 40px;
        height: 100%;
        @media (max-width: $updatesTouch) {
            position: relative !important;
            max-width: 680px;
            padding-top: 30px;
            left: auto;
            top: auto;
            bottom: auto;
        }
        .updates-filters-inner {
            max-width: 280px;
            width: 100%;
            @media (max-width: $updatesTouch) {
                position: relative !important;
                max-width: 620px;
                margin-left: auto;
                margin-right: auto;
            }
            &.js-is-sticky {
                padding-top: 100px;
                @media (max-width: 1440px) {
                    padding-top: 30px;
                }
                @media (max-width: $updatesTouch) {
                    padding-top: 0px;
                }
            }
            &.js-is-stuck {
                padding-bottom: 200px;
            }
            &.mobile-tags-open {
                @media (max-width: $updatesTouch) {
                    .filter-parent {
                        &.tags-filter {
                            display: block;
                            z-index: 30;
                        }
                        &.search-filter {
                            .filter-mobile-toggle {
                                background: rgba(#D2D6DC, 1);
                                svg {
                                    path {
                                        stroke: #252F3F;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .filter-parent + .filter-parent {
                margin-top: 44px;
            }
            .filter-parent {
                position: relative;
                overflow: hidden;
                &.search-filter {
                    @media (max-width: $updatesTouch) {
                        display: flex;
                        gap: 8px;
                    }
                    .filter-title {

                    }
                    .filter-inner {
                        @media (max-width: $updatesTouch) {
                            flex-grow: 1
                        }
                    }
                    .filter-mobile-toggle {
                        display: none;
                        padding: 11px;
                        background: rgba(#D2D6DC, 0);
                        border: 1px solid #E5E7EB;
                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                        border-radius: 6px;
                        transition: background-color 0.3s;
                        align-items: center;
                        gap: 8px;
                        .filter-mobile-count {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 18px;
                            width: 18px;
                            background: #EDF0F7;
                            border-radius: 2px;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 1;
                            color: #494B50;
                        }
                        svg {
                            path {
                                transition: stroke 0.3s;
                            }
                        }
                        @media (max-width: $updatesTouch) {
                            display: flex;
                        }
                    }
                    @media (max-width: $updatesTouch) {
                        max-width: 100%;
                    }
                }
                &.tags-filter {
                    max-width: 280px;
                    @media (max-width: $updatesTouch) {
                        display: none;
                        width: 100%;
                        background: #FFF;
                        position: absolute;
                        top: 8px;
                        right: 0px;
                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    }
                }
                .filter-title {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #2B3A5A;
                    margin-bottom: 12px;
                    @media (max-width: $updatesTouch) {
                        display: none;
                    }
                }
                .filter-inner {
                    position: relative;
                    padding: 28px 24px;
                    border: 1px solid #E5E7EB;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 6px;
                    overflow: hidden;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        // width: 100%;
                        width: 0%;
                        background: #2B3A5A;
                        height: 2px;
                        transition: width 0s;
                    }
                    &.is-loading {
                        &:after {
                            width: 100%;
                            // the following two properties time should add up to the debounceTime var in src/updates.js
                            transition: width .75s linear;
                            transition-delay: 1.5s;
                            // e.g. 0.75s + 1.5s = 2250 (ms)
                        }
                    }
                    .filter + .filter {
                        margin-top: 36px;
                    }
                    .filter {
                        .filter-subtitle {
                            margin-bottom: 16px;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            display: flex;
                            align-items: center;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            color: #6B7280;
                        }
                        .filter-option {
                            margin-bottom: 12px;
                            label {
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                color: #252F3F;
                                display: flex;
                                align-items: center;
                                input[type="checkbox"] {
                                    margin-right: 12px;
                                    accent-color: #585FF1;
                                }

                            }
                        }
                    }
                    &.is-field {
                        padding: 0px;
                        position: relative;
                        &.loading {
                            opacity: 0.5;
                        }
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 15px;
                            transform: translateY(-50%);
                        }
                    }
                    input[type="text"] {
                        border-radius: 6px;
                        width: 100%;
                        height: 38px;
                        line-height: 38px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #161E2E;
                        padding-left: 41px;
                        &::placeholder {
                            color: #6B7280;
                        }
                    }
                }
            }
        }
    }
    main.updates-content {
        max-width: 680px;
        width: 100%;
        .active-filters-container {
            padding-top: 100px;
            max-width: 620px;
            margin-left: auto;
            margin-right: auto;
            @media (max-width: $updatesTouch) {
                padding-top: 16px;
            }
            h4 {
                font-family: 'Barlow';
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 30px;
                display: flex;
                align-items: flex-end;
                color: #003C57;
                margin-bottom: 16px;
                @media (max-width: $updatesTouch) {
                    display: none;
                }
            }
            .active-filters {
                width: 100%;
                display: flex;  
                flex-wrap: wrap;
                gap: 9px;
                .active-filter {
                    cursor: pointer;
                    // padding: 10px 0px;
                    > div {
                        background: #EEF0FF;
                        border-radius: 4px;
                        color: #2D357F;
                        padding: 2px 10px;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        color: #2D357F;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        transition: opacity 0.15s;
                        span {
                            margin-right: 9px;
                        }
                        span, svg {
                            pointer-events: none;
                        }
                    }
                    &.loading {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    &.is-hidden {
                        display: none;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
                .active-filter + .active-filter {
                    // margin-left: 8px;
                }
            }
            .results-count {
                margin-top: 46px;
                font-family: 'Inter';
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #6B7280;
                padding-bottom: 8px;
                border-bottom: 1px #D2D6DC solid;
                @media (max-width: $updatesTouch) {
                    margin-top: 32px;
                }
            }
        }
        .updates {
            font-family: Inter;
            color: #494B50;
            max-width: 730px;
            border-radius: 6px;
            margin-top: 70px;
            @media (max-width: $updatesTouch) {
                margin-top: 40px;
            }
            .logo {
                img {
                    width: 60px;
                    @screen md {
                        width: 80px;
                    }
                }
            }
            .page-title {
                font-size: 32px;
                @screen md {
                    font-size: 42px;
                }
                font-weight: bold;
                padding: 20px 0 34px;
                border-bottom: 2px solid #F6F8FB;
            }
            .update {
                max-width: 620px;
                margin: 0 auto 80px;
                @media (max-width: $updatesTouch) {
                    margin: 0 auto 60px;
                }
                &.template {
                    display: none;
                }
                &:first-child {
                    margin-top: 60px;
                    @media (max-width: $updatesTouch) {
                        margin-top: 40px;
                    }
                }
                &.featured-update {
                    width: 100%;
                    max-width: 100%;
                    padding: 30px;
                    // margin-left: -30px;
                    // margin-right: -30px;
                    background: #F6F8FB;
                    border-radius: 6px;
                }
                .title {
                    margin: 0px 0 10px;
                    font-family: 'Barlow';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    color: #003C57;
                    @media (max-width: $updatesTouch) {
                        font-size: 21px;
                        line-height: 28px;
                    }
                }
                .update-meta {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    @media (max-width: $updatesTouch) {
                        margin-bottom: 12px;
                    }
                    svg.featured-icon {
                        margin-right: 15px;
                    }
                    .meta-date {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        color: #6B7280;
                        @media (max-width: $updatesTouch) {
                            font-size: 10px;
                            line-height: 12px;
                        }
                    }
                    svg.ellipse {
                        margin: 0px 8px;
                    }
                    .meta-module {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 15px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        color: #585FF1;
                        @media (max-width: $updatesTouch) {
                            font-size: 10px;
                            line-height: 12px;
                        }
                    }
                }
                .date {
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    color: #858E99;
                    margin: 10px 0 18px;
                    opacity: 0.75;
                    position: relative;
                    cursor: pointer;
                    &:after {
                        content: attr(data-date);
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: white;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:active {
                        &:after {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                .tags {
                    display: flex;
                    margin: 17px 0 10px;
                    .tag {
                        background-color: #333;
                        color: white;
                        font-size: 12px;
                        line-height: 25px;
                        height: 25px;
                        padding: 0 13px;
                        border-radius: 15px;
                        margin-right: 10px;
                    }
                }
                .text {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 28px;
                    p {
                        margin-bottom: 25px;
                    }
                    a {
                        color: rgb(62, 138, 246);
                    }
                    ul, ol {
                        list-style: inside;
                    }
                    @media (max-width: $updatesTouch) {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
                .videos {
                    margin-top: 24px;
                    max-width: 560px;
                    margin: 22px 0;
                    &:last-child {
                        margin-bottom: 59px;
                    }
                    video {
                        border-radius: 5px;
                    }
                }
                // Content blocks
                .updates-matrix {
                    .images {
                        img {
                            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
                        }
                    }
                    .text {
                        figure {
                            img {
                                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
                            }
                        }
                    }
                    .videos {

                    }
                    .landscape-image {
                        margin-top: 24px;
                        img {
                            max-height: 220px;
                            max-width: 560px;
                            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
                        }
                        @media (max-width: $updatesTouch) {
                            img {
                                max-width: 100%;
                                max-height: auto;
                            }
                        }
                    }
                    .portrait-images {
                        margin-top: 24px;
                        max-width: 600px;
                        display: flex;
                        gap: 40px;
                        @media (max-width: 768px) {
                            flex-direction: column;
                            gap: 20px;
                        }
                        .portrait-image {
                            width: calc(50% - 20px);
                            position: relative;
                            @media (max-width: 768px) {
                                width: 100%;
                            }
                            img {
                                width: 100%;
                                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
                            }
                            > div {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-size: cover;
                                background-position: center;
                                background-repeat: no-repeat;
                                box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
                            }
                        }
                    }
                    .carousel {
                        position: relative;
                        margin-top: 24px;
                        max-width: 560px;
                        .carousel-slides {
                            position: relative;
                            .carousel-slide {
                                .carousel-image {
                                    position: relative;
                                    padding-bottom: 56.25%;
                                    .image-inner {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        background-size: cover;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                    }
                                }
                                .carousel-info {
                                    padding-top: 14px;
                                    display: flex;
                                    justify-content: space-between;
                                    padding-right: 31px;
                                    .carousel-caption {
                                        font-family: 'Inter';
                                        font-style: italic;
                                        font-weight: 400;
                                        font-size: 13px;
                                        line-height: 22px;
                                        display: flex;
                                        align-items: center;
                                        text-align: justify;
                                        color: #494B50;
                                        opacity: 0.9;
                                    }
                                    .carousel-index {
                                        font-family: 'Inter';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 11px;
                                        line-height: 20px;
                                        display: flex;
                                        align-items: center;
                                        text-align: justify;
                                        color: #2B3A5A;
                                    }
                                }
                                &.active {
                                    .carousel-info {
                                        .carousel-index {

                                        }
                                    }
                                }
                            }
                        }
                        .carousel-controls {
                            position: absolute;
                            bottom: 4.5px;
                            right: 0;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 80px;
                            .glider-arrow {
                                position: static;
                            }
                        }
                    }
                }
                .updates-link {
                    display: inline-flex;
                    margin-top: 24px;
                    align-items: center;
                    gap: 10px;
                    transition: opacity 0.15s;
                    &:hover {
                        opacity: 0.7;
                    }
                    span {
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 28px;
                        color: #2B3A5A;
                    }
                    @media (max-width: $updatesTouch) {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }
            }
            .autoload {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #858E99;
                text-align: center;
            }
            .pagination-custom {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 50px;
                margin-bottom: 100px;
                @media (max-width: $updatesTouch) {
                    gap: 8px;
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    margin: 10px 4px;
                    border-radius: 2px;
                    transition: background-color 0.3s;
                    @media (max-width: $updatesTouch) {
                        margin: 0px
                    }
                    span {
                        transition: color 0.3s;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        color: #494B50;
                    }
                    svg {
                        path {
                            transition: fill 0.3s;
                        }
                    }
                    &.is-current,
                    &[href]:hover  {
                        background: #2B3A5A;
                        span {
                            color: #FFF;
                        }
                        svg {
                            path {
                                fill: #FFF;
                            }
                        }
                    }
                }
            }
        }
    }
}

body {
    &.updates-modal-open {
        overflow-y: hidden;
    }
    .loading-indicator {
        pointer-events: none;
        position: fixed;
        top: 0px;
        left: 0px;
        // width: 100%;
        width: 0%;
        background: #585FF1;
        height: 2px;
        transition: width 0s;
        z-index: 50;
        &.is-loading {
            width: 100%;
            // the following two properties time should add up to the debounceTime var in src/updates.js
            transition: width .75s linear;
            transition-delay: 1.5s;
            // e.g. 0.75s + 1.5s = 2250 (ms)
        }   
    }
    .updates-image-modal {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1010;
        align-items: center;
        justify-content: center;
        &.modal-open {
            display: flex;
        }
        .modal-bg {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(#0F172A, 0.6);
            z-index: -1;
        }
        .modal-content {
            position: relative;
            a.close-modal {
                cursor: pointer;
                position: absolute;
                top: -30px;
                right: 0px;
                &:hover {
                    opacity: 0.5;
                }
            }
            img {
                max-width: 90vw;
                max-height: 90vh;
            }
        }
    }
    img.modal-trigger-img {
        cursor: zoom-in;
    }
    .hidden-touch {
        @media (max-width: $updatesTouch) {
            display: none !important;
        }
    }
}