.video-banner-hero {
    width: 100%;
    max-width: 1630px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1740px) {
        width: calc(100% - 80px);
        margin: 0 40px;
    }
    @media (max-width: 768px) {
        width: calc(100% - 40px);
        margin: 0 20px;
    }
    padding-bottom: 60px;
    &:after {
        content: '';
        position: absolute;
        width: 250vw;
        height: 100vw;
        left: -67vw;;
        bottom: 100%;
        @media (max-width: 768px) {
            bottom: auto;
            top: -50vw;
        }
        transform: rotate(-20deg);
        background-color: #f6f8fb;
        z-index: -1;
    }
    .video-banner-banner {
        display: flex;
        align-items: center;
        width: 100%;
        @media (max-width: 1023px) {
            flex-direction: column-reverse;
        }
        .text {
            width: 50%;
            padding-right: 170px;
            &, * {
                // font-family: Inter;
                // color: rgb(43, 58, 90);
                // font-size: 70px;
                // font-weight: 600;
                // line-height: 90px;
            }
            @media (max-width: 1580px) {
                padding-right: 100px;
                &, * {
                    // font-size: 42px;
                    // line-height: 54px;
                }
            }
            @media (max-width: 1023px) {
                width: 100%;
                margin: 55px 0;
                padding-right: 0;
                &, * {
                    // font-size: 70px;
                    // line-height: 90px;
                }
            }
            @media (max-width: 767px) {
                margin: 39px 0 0;
                &, * {
                    // font-size: 32px;
                    // line-height: 44px;
                }
            }
            .button {
                position: relative;
                z-index: 1;
                color: white;
                font-family: Inter;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0px;
                line-height: 16px;
                text-align: center;
                border-radius: 4px;
                height: 50px;
                padding: 17px 28px;
                overflow: hidden;
                display: inline-flex;
                margin-top: 40px;
                .button-text {
                    position: relative;
                }
                .button-bg {
                    position: absolute;
                    border-radius: 4px;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #3e8af6;
                    filter: brightness(100%);
                    transition: filter .3s;
                }
                &:hover {
                    .button-bg {
                        filter: brightness(92%);
                    }
                }
            }
        }
        .video-preview {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 50%;
            @media (max-width: 1460px) {
                .preview-video {
                    width: 100%;
                }
            }
            @media (max-width: 1023px) {
                justify-content: center;
                width: 100%;
            }
            @screen lg {
                max-width: 800px;
            }
            .preview-video {
                position: relative;
                padding: 60px 0;
                @media (max-width: 1023px) {
                    padding: 50px 0;
                }
                .video-placeholder {
                    cursor: pointer;
                    position: relative;
                    width: 800px;
                    padding-bottom: 88%;
                    background: #aaa;
                    border-radius: 8px;
                    box-shadow: 0px 2px 64px 0px rgba(0, 0, 0, 0.06);
                    overflow: hidden;
                    @media (max-width: 1460px) {
                        width: 600px;
                    }
                    @media (max-width: 1460px) {
                        width: 100%;
                    }
                    .looping-video {
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                        transition: transform .35s, opacity .35s;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(#000, 0.3);
                            opacity: 0;
                            transition: opacity .35s;
                            z-index: 1;
                        }
                    }
                    .placeholder-background {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: transform .35s;
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: rgba(#000, 0.3);
                            opacity: 0;
                            transition: opacity .35s;
                        }
                    }
                    .play-icon {
                        z-index: 2;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -37px;
                        margin-left: -37px;
                        transition: opacity .35s;
                        svg {
                            height: 74px;
                            width: 74px;
                            [fill]:not([fill="none"]) {
                                fill: #0A3F8A;
                            }
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: 6px solid #0A3F8A;
                            border-radius: 50%;
                            transition: transform .35s .05s;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: #fff;
                            border-radius: 50%;
                            z-index: -1;
                            transition: transform .3s .05s, border-width .3s .05s;
                        }
                    }
                    .play-text {
                        z-index: 2;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 200px;
                        height: 40px;
                        margin-top: 60px;
                        margin-left: -100px;
                        transition: opacity .35s;
                        text-transform: uppercase;
                        text-align: center;
                        color: rgb(40, 58, 93);
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 2px;
                        line-height: 20px;
                        opacity: 0;
                        display: none;
                    }
                    &:hover {
                        .looping-video {
                            // transform: scale(1.03);
                            &:before {
                                // opacity: 1;
                            }
                        }
                        .placeholder-background {
                            transform: scale(1.03);
                            &:before {
                                opacity: 1;
                            }
                        }
                        .play-icon {
                            &:before {
                                transform: scale(1.11);
                            }
                        }
                        .play-text {
                            opacity: 1;
                        }
                        .play-case {
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
                .preview-triangles {
                    position: absolute;
                    bottom: 60px;
                    left: -80px;
                    z-index: 1;
                    svg {
                        width: 50px;
                    }
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
        .video-banner-video-modal {
            position: fixed;
            z-index: 9999;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
            &:not(.active) {
                &, * {
                    pointer-events: none !important;
                }
            }
            .modal-background {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0,0,0,0.4);
            }
            .modal-content {
                position: relative;
                width: 80%;
                @media (max-width: 768px) {
                    width: 100%;
                }
                max-width: 90vh * (16/9);
                .video-sizer {
                    width: 100%;
                    padding-bottom: 100% * (9/16);
                    pointer-events: none;
                }
                .video-wrapper, iframe, .plyr {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .video-banner-logos {
        margin: 136px auto 133px;
        @media (max-width: 1023px) {
            margin: 47px 0;
        }
        .logos-subheading {
            display: flex;
            justify-content: center;
            width: 100%;
            text-align: center;
            color: rgb(43, 58, 90);
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 2.33px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 50px;
        }
        .logos-logos {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                position: absolute;
                left: 100%;
                top: 50%;
                margin-top: -30px;
                transform: scaleX(-1);
                path {
                    stroke: black;
                }
            }
            a {
                margin: 0 40px;
                img {
                    height: 50px;
                    width: auto;
                    &.mobile-image {
                        display: none;
                    }
                }
            }
            @media (max-width: 1023px) {
                flex-wrap: wrap;
                & > svg {
                    display: none;
                }
                a {
                    width: 50%;
                    margin: 30px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        &.mobile-image {
                            display: block;
                        }
                        &.desktop-image {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}