.matrix-socialBlocks {
    position: relative;
    padding-top: 240px;
    padding-bottom: 80px;
    margin-top: -100px;
    .the-slant-tm {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        svg {
            width: 100%;
            height: auto;
        }
        &:after {
            content: '';
            position: static;
            width: 100%;
            background: #f6f8fb;
            flex-grow: 1;
        }
    }
    @media (max-width: 1230px) {
        margin-top: -30px;
        padding-top: 200px;
    }
    @media (max-width: 768px) {
        margin-top: -30px;
        padding-top: 90px;
    }
}
.social-blocks {
    position: relative;
    font-size: 0;
    width: 1260px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1230px) {
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
        .social-block {
            width: calc(50% - 40px) !important;
            margin: 20px;
        }
    }
    @media (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
        .social-block {
            width: calc(100% - 40px) !important;
        }
    }
    .social-block {
        width: calc(50% - 30px);
        display: flex;
        flex-direction: column;
        margin: 15px;
        padding-bottom: 90px;
        @media (max-width: 1200px) {
            padding-bottom: 35px;
        }
        @media (min-width: 1200px) {
            padding-right: 150px;
        }
        p {
            font-family: Inter;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 28px;
        }
        font-size: initial;

        border-radius: 8px;

        z-index: 20;
        .social-icons {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 32px;
            @media (max-width: 768px) {
                margin-top: 20px;
            }
            a {
                margin-right: 20px;
                transition: transform .3s;
            }
            // only hover on items with a link
            a[href] {
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}