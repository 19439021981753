.legal-page {
    .legal-page-header {
        position: relative;
        background: #f6f8fb;
        text-align: center;
        padding: 60px 0px 80px;
        margin-bottom: 100px;
        @media (max-width: 1230px) {
            padding: 60px 40px 80px;
            margin-bottom: 40px;
        }
        @media (max-width: 992px) {
            padding: 40px 20px 30px 20px;
            text-align: left;
            h1 {
                padding-top: 3px;
                margin: 0px;
            }
        }
        .legal-page-title {
            position: relative;
            svg {
                display: none;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                margin-top: 5px;
                transition: all 0.3s;
                @media (max-width: 992px) {
                    display: initial;
                }
            }
            &.active {
                svg {
                    transform: translateY(-50%) rotate(-180deg) !important;
                }
            }
        }
        .subheading {
            color: rgb(133, 142, 153);
            font-size: 12px;
            font-family: Inter;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            opacity: 0.75;
            @media (max-width: 992px) {
                padding: 0px;
                margin: 0px;
                text-align: left;
            }
        }
        .legal-page-mobile-nav {
            position: absolute;
            width: calc(100% - 40px);
            border-radius: 4px;
            // animated properties
            top: 80%;
            pointer-events: none;
            padding: 0px 24px;
            background: rgba(#ffffff, 0);
            box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0);
            transition: background-color 0.4s 0.1s, top 0.4s 0.1s, box-shadow 0.4s 0.1s, padding 0.4s 0.1s;
            &.active {
                top: 90%;
                pointer-events: initial;
                padding: 28px 24px;
                background: #ffffff;
                box-shadow: 0px 10px 34px 0px rgba(0, 0, 0, 0.06);
                transition: background-color 0.5s, top 0.5s, box-shadow 0.5s, padding 0.5s;
                ul {
                    li {
                        a {
                            opacity: 1;
                            transition: opacity 0.3s 0.2s;
                        }
                    }
                }
            }
            ul {
                li {
                    margin-bottom: 14px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    a {
                        display: block;
                        color: rgb(43, 58, 90);
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        opacity: 0;
                        transition: opacity 0.3s;
                    }
                }
            }
        }
    }
    .legal-page-main {
        width: 1230px;
        margin: 0px auto 200px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 1230px) {
            width: 100%;
            padding: 0px 40px;
            min-height: auto;
            margin-bottom: 60px;
        }
        @media (max-width: 768px) {
            padding: 0px 20px;
        }
        .legal-page-content {
            flex: 1 0;
            padding-right: 110px;
            width: 100%;
            @media (max-width: 1100px) {
                padding-right: 70px;
            }
            @media (max-width: 992px) {
                padding-right: 0px;
            }
            .legal-matrix-downloads,
            .legal-matrix-richText {
                margin-bottom: 50px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
            .legal-matrix-richText {
                ul, ol {
                    list-style: initial;
                    padding-left: 35px;
                    padding-bottom: 8px;
                    li {
                        padding-left: 5px;
                        color: rgb(73, 75, 80);
                        font-size: 18px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        line-height: 32px;
                        margin-bottom: 12px;
                        @media (max-width: 768px) {
                            font-size: 16px;
                            line-height: 28px;
                        }
                        ul, ol {
                            margin-top: 12px;
                            padding-bottom: 0px;
                        }
                    }
                }
                h2 {
                    margin-top: 80px;
                    margin-bottom: 20px;
                    @media (max-width: 768px) {
                        margin-top: 48px;
                        font-size: 28px;
                        line-height: 40px;
                    }
                    &:first-child {
                        margin-top: 0px !important;
                    }
                }
                h3 {
                    margin-top: 60px;
                    margin-bottom: 20px;
                    @media (max-width: 768px) {
                        margin-top: 40px;
                        font-size: 24px;
                    }
                }
                h2 + h3 {
                    margin-top: 48px !important;
                    @media (max-width: 768px) {
                        margin-top: 24px !important;
                    }
                }
                h4 {
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 0px;
                    margin-top: 48px;
                    @media (max-width: 768px) {
                        font-size: 20px;
                        margin-top: 32px;
                    }
                }
                h3 + h4 {
                    margin-top: 40px !important;
                    @media (max-width: 768px) {
                        margin-top: 24px !important;
                    }
                }
                p {
                    margin-bottom: 20px;
                    color: rgb(73, 75, 80);
                    font-size: 18px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    line-height: 32px;
                    &.lead {
                        color: rgb(73, 75, 80);
                        font-size: 22px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        line-height: 36px;
                        @media (max-width: 768px) {
                            font-size: 19px;
                            line-height: 31px;
                        }
                    }
                    @media (max-width: 768px) {
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
                .table-wrapper {
                    width: 100%;
                    overflow-x: auto;
                    white-space: nowrap;
                    @media (max-width: 768px) {
                        width: 100vw;
                        overflow-x: auto;
                        // slight hack to get the table to scroll to the edge of the page
                        margin-left: -20px;
                        padding-left: 20px;
                    }
                }
                table {
                    width: 100%;
                    @media (max-width: 768px) {
                        border-right: 20px solid #FFFFFF;
                    }
                    thead {
                        border-bottom: 2px solid rgb(236, 241, 247);
                        border-radius: 4px 4px 0px 0px;
                        tr {
                            th {
                                line-height: 60px;
                                text-align: left;
                                color: rgb(43, 58, 90);
                                font-size: 12px;
                                font-weight: bold;
                                text-transform: uppercase;
                                letter-spacing: 2px;
                                @media (max-width: 768px) {
                                    min-width: 250px;
                                    &:last-child {
                                        min-width: 130px;
                                    }
                                }
                            }
                        }
                    }
                    tbody {
                        tr {
                            border-bottom: 1px solid rgb(236, 241, 247);
                            border-radius: 0px;
                            td {
                                line-height: 70px;
                                text-align: left;
                                color: rgb(43, 58, 90);
                                font-size: 16px;
                                font-family: Barlow;
                                font-weight: normal;
                                letter-spacing: 0px;
                                &:first-child {
                                    font-weight: 500;
                                }
                                @media (max-width: 768px) {
                                    &:nth-child(1),
                                    &:nth-child(2) {
                                        padding-right: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .legal-matrix-downloads {
                width: 100%;
                @keyframes arrowhover {
                    0% {
                        transform: translateY(0px);
                    }
                    50% {
                        transform: translateY(4px);
                    }
                    100% {
                        transform: translateY(0px);
                    }
                }
                .downloads-table {
                    display: table;
                    width: 100%;
                    border-collapse: collapse;
                    border-top: 1px solid #eaeaea;
                    @media (max-width: 992px) {
                        display: block;
                    }
                    a {
                        line-height: 68px;
                        width: 100%;
                        display: table-row;
                        transition: background-color 0.3s;
                        border-bottom: 1px solid #eaeaea;
                        @media (max-width: 992px) {
                            display: block;
                            line-height: 26px;
                            padding: 22px 0px;
                        }
                        > span {
                            display: table-cell;
                            &:last-child {
                                padding: 0px 20px 0px 0px;
                            }
                            &:first-child {
                                padding: 0px 0px 0px 20px;
                            }
                            &.muted {
                                text-align: right;
                                color: rgb(133, 142, 153);
                                font-size: 14px;
                                font-weight: 300;
                                letter-spacing: 0px;
                            }
                            @media (max-width: 992px) {
                                display: block;
                                &:last-child,
                                &:first-child {
                                    padding: 0px;
                                }
                                &.muted {
                                    text-align: left;
                                    padding-left: 24px;
                                    line-height: 17px;
                                    & + .muted {
                                        padding-top: 8px;
                                    }
                                }
                            }
                        }
                        .download-title {
                            color: rgb(43, 58, 90);
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0px;
                            @media (max-width: 992px) {
                                margin-bottom: 16px;
                            }
                            .download-icon {
                                display: inline-block;
                                margin-right: 5px;
                                svg {
                                    height: 14px;
                                    width: 14px;
                                }
                            }
                        }
                        &:hover {
                            .download-icon {
                                animation: arrowhover 0.6s;
                            }
                            background-color: rgba(#F6F8FB, 0.5);
                        }
                    }
                }
            }
        }
        .legal-page-sidebar {
            flex: 0 0 300px;
            @media (max-width: 1100px) {
                flex: 0 0 250px;
            }
            @media (max-width: 992px) {
                display: none;
            }
            .legal-page-sidebar-inner {
                // make sure sidebar transitions smoothly when is-fixed is applied
                padding-top: 60px;
                margin-top: -60px;
                &.is-fixed {
                    margin-top: 0px;
                    position: fixed;
                    top: 0px;
                }
                &.is-anchored {
                    position: absolute !important;
                    top: auto !important;
                    // bottom is calculated in JS
                }
            }
            .legal-page-sidebar-title {
                color: rgb(43, 58, 90);
                font-size: 12px;
                font-weight: bold;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 28px;
            }
            ul.legal-page-sidebar-nav {
                li {
                    margin-bottom: 20px;
                    a {
                        color: rgb(133, 142, 153);
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        padding: 2px 0px;
                        transition: colour 0.3s;
                        &:hover {
                            color: #2B3A5A;
                        }
                    }
                    &.active {
                        a {
                            color: rgb(43, 58, 90);
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
