/*! purgecss start ignore */
.container {
    @apply w-full mx-auto px-5;
    max-width: 1270px;
}

.footer {
    .copyright-text,
    .legal,
    .language {
        font-size: 13px;
    }
}

.link-arrow {
    &:after {
        content: '→';
        display: inline-block;
        transition: transform 0.4s, opacity 0.4s;
        transform: translate(5px);
        opacity: 0;
        margin-left: 5px;
    }
    &:hover:after {
        transform: translate(0px);
        opacity: 1;
    }
}

.wrapper[type="features"]:not([slug="funnel"]):not([slug="apps"]):not([slug="websites"]),
.wrapper[nav-type="dark"] {
    .d-navigation {
        .digistorm-logo {
            @apply hidden;
        }
        .digistorm-logo-white {
            @apply block;
        }
        .main-page,
        .support-link,
        .mobile-nav {
            @apply text-white;
        }
    }
}

@import 'scss/typography.scss';
@import 'scss/components/all.scss';
@import 'scss/pages.scss';
@import 'scss/products.scss';
@import 'scss/module.scss';
@import 'scss/updates.scss';
@import 'scss/pages-v2.scss';
@import 'scss/legal.scss';
@import 'scss/demo.scss';
@import 'scss/customer-stories.scss';
@import 'scss/404.scss';

@import 'scss/campaign_components/_all';

@import '../../node_modules/plyr/src/sass/plyr.scss'
/*! purgecss end ignore */
